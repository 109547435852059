.chords {
	//  display:flex;
	//  flex-direction:column;

	background-color: $color-dark-bg;
	border: 1px solid $color-pink;
	transition: all 0.5s ease-in-out;
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);

	position: absolute;
	z-index: 11;
	padding: 5px 3px;
	border-radius: 10px;
	left: 50%;
	transform: translate(-50%, 0%);

	.pasteChords {
		padding: 0px 0px 0px 5px;
		margin: 0px 0px 0px 5px;
		border-left: 2px solid #554f5d;
	}

	.chordTypes,
	.chordNotes {
		display: flex;
		position: relative;
		flex-wrap: nowrap;
		align-items: flex-start;
		justify-content: space-between;
		span {
			@extend %btn;
			background: #554f5d;
			color: $color-pink;
			margin: 0px 2px;
			font-weight: 500;
			font-size: 14px;

			&.close {
				background: url('../../images/Kos-malej.svg') no-repeat center center $color-delete;
				background-size: 12px 14px;
				position: absolute;
				right: 0;
				top: 0;
				color: transparent;
			}
		}
	}

	.chordTypes {
		display: flex;
		align-content: center;
		justify-content: center;
		margin-bottom: 5px;
		&.hidden {
			display: none;
		}
	}
	.chordNotes {
		display: flex;
		align-content: center;
		justify-content: center;
	}

	@include respond-to(mobile-only) {
		.chordTypes,
		.chordNotes {
			span {
				font-size: 12px;
				padding: 0px 14px;
			}
		}

		.close {
			top: 7px;
		}
	}

	@include respond-to(small-mobile-only) {
		.chordTypes,
		.chordNotes {
			span {
				font-size: 12px;
				line-height: 24px;
				padding: 0px 7px;
				margin: 0px 1px;
			}
		}

		.close {
			top: 7px;
		}
	}
}

.chords_arrow {
	position: absolute;
	z-index: 2;
	padding: 0px 5px;

	width: 300px;
	height: 30px;
	margin-bottom: -10px;
	background-image: url('../../images/arrow-acord.svg');
	background-position: 4px top;
	background-repeat: no-repeat;
	background-color: transparent;
}
