#add_url {
	@extend %cf;
	display: block;
	width: 100%;

	padding: 40px 0px 40px;

	p {
		margin-bottom: 20px;
	}

	> button {
		float: none;
	}

	@include respond-to(mobile-only) {
		> label {
			display: none;
		}

		> input {
			width: calc(100% - 100px);
			float: left;
		}

		> button {
			width: 90px;
			float: right;
		}
	}

	@include respond-to(tablet-portrait) {
		> label {
			line-height: 36px;
			display: block;
			float: left;
			margin-right: 10px;
		}

		> input {
			width: 300px;
			float: left;
		}

		> button {
			width: auto;
			float: left;
			margin-left: 10px;
		}
	}
}
