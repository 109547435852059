body {
	padding: 66px 20px 20px;
	background: url('../../images/patern-final-medium.png') repeat;
	color: #fff;
	font-family: $font-family;
	user-select: none;
	font-size: 16px;

	&:before {
		content: '';
		position: absolute;
		height: 60vh;
		width: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0.5) 0%,
			rgba(0, 0, 0, 0) 100%
		);
	}

	@include respond-to(tablet-portrait) {
		padding: 86px 40px 40px;
	}
}

#content {
	position: relative;
}

%logo {
	display: block;
	font-size: 0;
	line-height: 0;
	width: 80%;
	max-width: 613px;
	height: auto;
	background: url('../../images/Logo-big.png') no-repeat center center;
	background-size: contain;

	&:after {
		content: '';
		display: block;
		padding-bottom: 24.1435%;
	}
}

.logo {
	@extend %logo;
}
