.alphabet_wrap {
	@extend %cf;
	width: 100%;
	clear: both;
	overflow: auto;

	@include respond-to(mobile-only) {
		width: calc(100% + 40px);
		margin-left: -20px;
	}
}

.nav-pills {
	@extend %cf;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
	> li {
		margin-right: 2px;

		> a,
		> button {
			@extend %btn;
			height: auto;
			display: block;
			padding: 8px 8px;
			margin-bottom: 2px;
			text-transform: uppercase;
			line-height: 20px;
			width: 100%;
		}
	}

	@include respond-to(mobile-only) {
		&:not(.transposition) {
			display: flex;
			align-items: flex-start;
			flex-wrap: nowrap;
			overflow-x: scroll;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			padding: 0px 20px;
			margin: 0;

			&::-webkit-scrollbar {
				/* WebKit */
				width: 0;
				height: 0;
			}

			> li {
				margin-right: 6px;
			}
		}
	}

	@include respond-to(tablet-portrait) {
		flex-wrap: nowrap;
		align-items: flex-start;
		justify-content: space-between;
		> li {
			flex-grow: 1;
			max-width: 60px;
			margin-right: 5px;

			&:last-child {
				margin-right: 0;
			}
		}

		&.transposition {
			margin-bottom: 14px;
			> li {
				> a,
				> button {
					padding: 8px 15px;
				}
			}
		}
	}
}

.editMode {
	.nav-pills {
		&.transposition {
			pointer-events: none;
			opacity: 0.5;
		}
	}
}
