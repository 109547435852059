.vote {
	@extend %cf;
	margin-bottom: 14px;

	button {
		@extend %bg-fade;
		padding-left: 40px;
		margin-left: 6px;

		&:before,
		&:after {
			width: 40px;
		}

		&.up,
		&.down {
			&:before {
				background-image: url('../../images/vote-pink.svg');
				background-size: 17px 17px;
			}
			&:after {
				background-image: url('../../images/vote.svg');
				background-size: 17px 17px;
			}

			&.voted {
				pointer-events: none;
			}
		}

		&.down {
			&:before,
			&:after {
				transform: rotate(180deg);
			}
		}
	}
}

.votes {
	display: block;
	height: 20px;
	line-height: 20px;
	padding-left: 24px;
	background: url('../../images/vote.svg') no-repeat left center;
	background-size: 17px 17px;
}

.centered,
.centered-cols {
	.vote {
		float: right;
	}
}
