.centered {
	@extend %cf;
	max-width: 602px;
	margin: auto;
	position: relative;
}

.centered-cols {
	width: 100%;
	@include respond-to(tablet-landscape) {
		display: table;
		table-layout: fixed;
		max-width: 1057px;
		margin: auto;
	}
}

.left-col {
	@extend %cf;
	max-width: 602px;
	margin: auto;
	position: relative;
	z-index: 9;

	@include respond-to(tablet-landscape) {
		margin: 0px !important;
		width: 602px;
		display: table-cell !important;
		float: none !important;
	}
}

.right-col {
	display: none;
	@include respond-to(tablet-landscape) {
		display: table-cell;
		width: auto;
		width: calc(100% - 602px);
		position: relative;
		z-index: 8;
	}
}

.used-chords {
	@extend %cf;
	display: none;

	@include respond-to(tablet-landscape) {
		display: block;
		max-width: 455px;

		&.fixed {
			left: 0px;
			width: 100%;
			position: absolute;
			top: 0;
		}

		.acc-info {
			float: left;
			margin: 0px 0px 20px 20px;
			strong {
				display: block;
				color: $color-white;
				text-align: center;
				padding: 0px 0px 10px;
			}
		}

		.accord {
			position: relative;
			display: block;
			opacity: 1;
			top: 0;
			left: 0;
			transform: none;
		}
	}
}

.source {
	display: none;
}

.edit-toolbar {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	position: relative;
	z-index: 11;

	a,
	button {
		@extend %btn;
		height: 36px;
		padding: 0px 45px 0px 20px;
		margin: 0px 0px 0px 6px;

		&:hover {
			color: $color-pink-hover;
		}

		&.edit {
			background: url('../../images/Upravit.svg') no-repeat right 15px center $color-button-bg;
		}

		&.remove {
			background: url('../../images/Kos-malej.svg') no-repeat right 15px center $color-delete;
		}

		&.cancel {
			background: url('../../images/Zrusit.svg') no-repeat right 15px center #2d2e2f;
		}

		&.back {
			padding: 0px 20px 0px 45px;
			background: url('../../images/Zpet.svg') no-repeat left 15px center #2d2e2f;
		}

		&.save {
			background: url('../../images/Ulozit.svg') no-repeat right 15px center $color-button-bg;
		}

		&.add-to-playlist {
			background: url('../../images/Pridat.svg') no-repeat right 15px center $color-button-bg;
		}
	}

	@include respond-to(tablet-portrait) {
		position: absolute;
		top: 0;
		right: 0;
	}
}

#singleSong {
	#songbody {
		display: none;
	}

	&.body-visible {
		#songbody {
			display: block;
		}
	}

	.copy-row,
	.paste-row {
		display: block;
		color: $color-white;
		transition: opacity 0.2s ease-in;
		cursor: pointer;
		border-radius: 10px;
		background: url('../../images/copy_acord.svg') no-repeat center center $color-dark-bg;
		background-size: contain;
		background-origin: content-box;
	}

	.copy-row {
		width: 28px;
		height: 27px;
		padding: 5px;
		position: absolute;
		right: 0px;
		top: -4px;
		opacity: 0;
		pointer-events: none;
	}

	.paste-row {
		width: 33px;
		height: 37px;
		background-size: 29px 33px;
	}

	&.saving {
		pointer-events: none;
		opacity: 0.5;
	}

	&.editMode {
		#songbody {
			//border: 5px solid #49ad76;
			//padding-top: 15px;
			//padding-bottom: 30px;

			.chordsRow {
				.copy-row {
				}

				&:hover {
					.copy-row {
						opacity: 1;
						pointer-events: all;
					}
				}
			}

			.songRow {
				border: 1px solid $color-dark;
				i {
					display: block;
					width: 0;
					height: 0;
					border-radius: 50%;
					background: $color-pink;
					color: $color-white;
					position: absolute;
					right: 2px;
					opacity: 0;
					transition: opacity 0.2s ease-in;
					cursor: pointer;
					font-style: normal;

					&:hover {
						opacity: 1 !important;
					}

					&:before {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						line-height: 20px;
						font-weight: 400;
						text-align: center;
						background-repeat: no-repeat;
						background-position: center;
					}

					&:after {
						content: '';
						display: block;
						position: absolute;
						width: calc(100% + 10px);
						height: calc(100% + 10px);
						left: -5px;
					}

					&.addRow {
						background-color: $color-dark;
						&:before {
							background-image: url('../../images/Plus-v-kolecku.svg');
							background-size: 18px 18px;
						}

						&:after {
							top: -5px;
						}

						&.up {
							top: -26px;
						}

						&.down {
							bottom: -26px;
						}
					}
					&.removeRow {
						bottom: 2.5px;
						border: 1px solid $color-dark;
						&:before {
							background-image: url('../../images/Minus.svg');
							background-size: 10px 2px;
						}
						&:after {
							top: -8px;
						}
					}
				}

				&:hover,
				&.active {
					z-index: 9;
					background-color: #c0c3c8;
				}

				&:hover {
					i {
						opacity: 0.8;
						width: 22px;
						height: 22px;
					}
				}

				&.active {
					background-color: $color-dark;
					textarea {
						color: $color-white;
					}
				}
			}
		}
	}
}

#header {
	padding-bottom: 20px;

	h1,
	h2,
	a,
	input {
		margin-bottom: 5px;
	}
	h1,
	h2,
	input {
		display: block;
		border: 2px solid transparent;
		position: relative;
		left: 0px;
		padding: 8px;
		font-family: $font-family-bold;
		font-weight: 700;
		color: $color-white;
	}

	h2 {
		font-weight: 400;
	}

	::placeholder {
		color: $color-placeholder;
	}

	input {
		width: auto;
		min-width: auto;
		font-size: 30px;
		height: auto;
		font-weight: 800;
		color: $color-white;

		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
		border: 2px solid $color-pink-transparent;
		background: $color-button-bg-transparent;

		position: relative;

		+ input {
			margin-top: 5px;
			font-size: 20px;
			font-weight: 400;
		}
	}

	@include respond-to(tablet-portrait) {
		input {
			font-size: 50px;
			+ input {
				font-size: 30px;
			}
		}
	}
}

#songbody {
	position: relative;
	float: left;
	min-width: 100%;
	font: 16px/20px monospace !important;

	margin: 0px 0 15px !important;
	padding: 25px 15px;
	//border: 5px solid #fff;
	background: #d8d8d8;
	color: $color-dark;

	.songRowWrap {
		float: left;
		width: 100%;
		clear: both;
	}

	.chordsRow {
		position: relative;
		float: left;
		width: 100%;
		height: 25px;
		margin-top: 7px;
		outline: 0 !important;
		&.defaultMode {
			//margin: -10px 0;
		}
		.chord {
			position: absolute;
			top: 4px;
			color: $color-dark;
			font-weight: 700;

			&:hover {
				.accord {
					display: block;
					opacity: 1;
					pointer-events: all;
				}
			}

			&:before {
				content: '';
				display: block;
				position: absolute;
				width: calc(100% + 14px);
				height: calc(100% + 4px);
				left: -7px;
				top: -2px;
				border-radius: 10px;
				background: $color-pink-transparent;
				border: 1px solid $color-border-transparent;
			}

			//margin-left: -40px;
			@include respond-to(tablet-portrait) {
				// margin-left: -60px;
			}
		}
	}
	.songRow {
		position: relative;
		float: left;
		width: 100%;
		margin: 1px 0;
		min-height: 28px;
		z-index: 1;
		user-select: text;
		border-radius: 5px;
		border: 1px solid transparent;
		padding-left: 5px;

		&:nth-child(even) {
			background: rgba(30, 36, 50, 0.06);
		}

		input[type='text'],
		textarea {
			display: block;
			width: 100%;
			overflow: hidden;
		}
		textarea {
			resize: none;
		}

		i {
			display: none;
		}

		span,
		input[type='text'],
		textarea {
			font: inherit;
			line-height: 28px;
			height: auto;
			white-space: pre;
			padding: 0;
			border: 0;
			min-width: 15ch;
			border-radius: 0;
			padding-left: 0;
			padding-right: 0;
			background: transparent;
			&:focus {
				//        border-color: #ad4980 !important;
				//        padding: 10px 0px;
			}
		}

		span {
			user-select: text;
		}

		input[type='text'],
		textarea {
			-webkit-appearance: none;
		}
	}
}

.instrument-votes {
	@extend %cf;
	width: 100%;
	padding-top: 20px;
}

.editMode {
	.instrument-votes {
		pointer-events: none;
		opacity: 0.5;
	}
}

.instruments {
	@extend %cf;
	margin-bottom: 14px;
	float: left;

	.isntr {
		@extend %btn;
		@extend %bg-fade;
		padding-right: 40px;
		margin-right: 6px;

		&:before,
		&:after {
			width: 40px;
			left: auto;
			right: 0;
		}

		&.ukulele {
			&:before {
				background-image: url('../../images/ukulele.svg');
				background-size: 17px 17px;
			}
			&:after {
				background-image: url('../../images/ukulele-dark.svg');
				background-size: 17px 17px;
			}
		}

		&.guitar {
			&:before {
				background-image: url('../../images/kytara.svg');
				background-size: 17px 17px;
			}
			&:after {
				background-image: url('../../images/kytara-dark.svg');
				background-size: 17px 17px;
			}
		}
	}
}

// accords
$stringsOffset: 20px;
$fretsOffset: 20px;
$topOffset: 1px;
$middleOffset: 9px;

$stringsOffsetSmall: 9.9px;
$fretsOffsetSmall: 10px;
$topOffsetSmall: 1px;
$middleOffsetSmall: 4px;

.accord {
	transition: opacity 0.2s ease-in;
	position: absolute;
	top: 25px;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 10px;
	background: rgba(68, 62, 73, 0.71);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	display: none;
	opacity: 0;
	z-index: 99;
	text-align: center;
	padding: 15px;

	> span {
		display: block;
		text-transform: uppercase;
	}
	.head {
		display: block;
		height: 80px;
		position: relative;
		border-radius: 6px;
	}

	.finger {
		position: absolute;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background: #e7d3d5;
		margin-left: -7.5px;
		margin-top: -7.5px;
		display: none;

		&.pressed {
			display: block;
		}

		@for $i from 1 through 6 {
			&.s#{$i} {
				left: ($i - 1) * $stringsOffset;
			}
		}
		@for $i from 1 through 5 {
			&.r#{$i} {
				top: $topOffset + $middleOffset + ($i - 1) * $fretsOffset;
			}
		}
	}

	&.sm {
		border-radius: 10px;
		padding: 7px;

		.head {
			height: 40px;
			border-radius: 3px;
		}

		.finger {
			width: 7px;
			height: 7px;
			margin-left: -3.5px;
			margin-top: -3.5px;

			@for $i from 1 through 6 {
				&.s#{$i} {
					left: ($i - 1) * $stringsOffsetSmall;
				}
			}
			@for $i from 1 through 5 {
				&.r#{$i} {
					top: $topOffsetSmall + $middleOffsetSmall + ($i - 1) * $fretsOffsetSmall;
				}
			}
		}
	}

	&.guitar {
		width: 130px;
		.head {
			background: url('../../images/struny.svg') no-repeat center top #443e49;
		}

		&.sm {
			width: 65px;

			.head {
				background-size: contain;
			}
		}
	}

	&.ukulele {
		width: 91px;
		.head {
			background: url('../../images/struny4.svg') no-repeat center top #443e49;
		}

		&.sm {
			width: 45px;
			.head {
				background-size: contain;
			}
		}
	}
}

/* add new */

.addNewUsing {
	@extend %cf;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 40vh;
	width: 100%;
}
