$color-white: #fff;
$color-white-transparent: rgba(255, 255, 255, 0.8);

$color-light: #f9f9f9;
$color-dark: #333;
$color-black: #000000;
$color-placeholder: #ababab;
$color-cadillac: #ad4980;
$color-hover: #d1358b;

$color-alto: #ddd;
$color-light-grey: #eee;
$color-mouse-grey: #f3f3f3;
$color-green: #49ad76;
$color-spring-green: #00f66f;
$color-dove-grey: #666;
$color-red: #c10000;

$color-edit: #49ad76;
$color-yellow: #f8d125;

/* new */
$color-dark-bg: #222939;

$color-pink: #fecdd2;
$color-pink-dark: #fecdd2;
$color-pink-hover: #fff2f4;
$color-pink-transparent: rgba(254, 205, 210, 0.5);
$color-button-bg: #1e2432;
$color-button-bg-dark: #191b22;
$color-button-bg-transparent: rgba(254, 205, 210, 0.2);
$color-dark: #1e2432;
$color-active: #fecdd2;
$color-active-transparent: rgba(254, 205, 210, 0.6);
$color-border-transparent: rgba(30, 36, 50, 0.5);

$color-delete: #590b0b;
$color-delete-hover: #360b0b;

$font-family-headings: 'Pacifico', sans-serif;
$font-family: 'Roboto', sans-serif;
$font-family-light: 'Roboto', sans-serif;
$font-family-bold: 'Roboto', sans-serif;

$minw-mp: 320px;
$minw-ml: 480px;
$minw-tp: 750px;
$minw-tl: 1024px;
$minw-d: 1100px;
$minw-dxl: 1400px;
