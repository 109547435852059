.user-row {
	display: flex;
	justify-items: auto;
	justify-content: space-between;
	line-height: 20px;
	position: fixed;
	width: 100%;
	left: 0px;
	top: 0px;
	background: transparent;
	padding: 30px 20px 10px;
	z-index: 999;
	transition: all 0.3s ease-in-out;

	a,
	button {
		@extend %btn;
		padding: 0px 45px 0px 20px;

		&:hover {
			color: $color-pink-hover;
			background-color: $color-button-bg;
		}
	}

	a {
		height: 36px;
		&.back {
			padding: 0px 20px 0px 45px;
			background: url('../../images/Zpet.svg') no-repeat left 15px center $color-button-bg;
		}

		&.add-song {
			background: url('../../images/Pridat.svg') no-repeat right 15px center $color-button-bg;
		}
		&.history {
			background: url('../../images/Mask.svg') no-repeat right 15px center $color-button-bg;
		}
		&.login {
			background: url('../../images/Odhlasit.svg') no-repeat right 15px center $color-button-bg;
		}

		&.logout {
			background: url('../../images/Odhlasit.svg') no-repeat right 15px center $color-button-bg;
		}

		&.logged-in {
			background: $color-button-bg;

			figure {
				position: absolute;
				top: 4px;
				right: 4px;
				width: 30px;
				height: calc(100% - 8px);
				overflow: hidden;
				border-radius: 7px;
				img {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 100%;
					height: auto;
					min-width: 100%;
					min-height: 100%;
					object-fit: cover;
					transform: translate(-50%, -50%);
				}
			}
		}
	}

	button {
		height: 30px;
		line-height: 30px;
		padding: 0px 20px 0px 45px;
		text-transform: none;
		border-radius: 5px;
		text-align: left;

		&.facebook {
			background: url('../../images/facebook-logo.svg') no-repeat left 15px center #4267b2;
			color: $color-white;

			&:hover {
				color: $color-white;
				text-decoration: underline;
			}
		}

		&.gplus {
			background: url('../../images/google_logo@3x.svg') no-repeat left 15px center #ffffff;
			color: $color-dark;
			&:hover {
				color: $color-dark;
				text-decoration: underline;
			}
		}
	}

	> strong {
		display: inline-block;
		font-weight: normal;
	}

	@include respond-to(mobile-only) {
		a {
			&.back,
			&:not(.login) {
				color: transparent;
				padding: 0px;
				width: 40px;
				background-position: center !important;
			}
		}
	}

	@include respond-to(tablet-portrait) {
		padding: 30px 40px 10px;
	}
}

.scrolling {
	.user-row {
		padding-top: 10px;
		background: $color-button-bg-transparent;
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
	}
}

.actions {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	li {
		display: inline-block;
		position: relative;

		a {
		}

		nav {
			border-top: 10px solid transparent;
			position: absolute;
			top: 36px;
			overflow: hidden;
			right: 0;
			opacity: 0;
			pointer-events: none;
			transition: all 0.2s ease-in;
		}

		ul {
			border-radius: 10px;
			border: 1px solid $color-pink-transparent;
			padding: 5px;
			background: $color-button-bg-transparent;
			-webkit-backdrop-filter: blur(5px);
			backdrop-filter: blur(5px);
			li,
			button,
			a {
				display: block;
				width: 100%;
				white-space: nowrap;
				margin: 0px;

				+ li {
					margin-top: 5px;
				}
			}
		}

		&:hover,
		&.show-nav {
			nav {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	&.left {
		> ul {
			> li {
				margin-right: 6px;
			}
		}
	}

	&.right {
		> ul {
			> li {
				margin-left: 6px;
			}
		}
	}
}
