@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/subset-Roboto-Regular.woff2') format('woff2'),
		url('../../fonts/subset-Roboto-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/subset-Roboto-Bold.woff2') format('woff2'),
		url('../../fonts/subset-Roboto-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/subset-Roboto-MediumItalic.woff2') format('woff2'),
		url('../../fonts/subset-Roboto-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/subset-Roboto-Italic.woff2') format('woff2'),
		url('../../fonts/subset-Roboto-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/subset-Roboto-BoldItalic.woff2') format('woff2'),
		url('../../fonts/subset-Roboto-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/subset-Roboto-Medium.woff2') format('woff2'),
		url('../../fonts/subset-Roboto-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Pacifico';
	src: url('../../fonts/Pacifico-Regular.woff2') format('woff2'),
		url('../../fonts/Pacifico-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
