#history-link {
	@extend %btn;
	position: absolute;
	top: 0;
	right: 0;

	@include respond-to(mobile-only) {
		width: 90px;
	}
}
