%btn {
	background: $color-button-bg-transparent;
	color: $color-pink;
	padding: 0px 18px;
	border-radius: 10px;
	line-height: 36px;
	font-size: 13px;
	text-transform: uppercase;
	transition: all 0.2s ease-in;
	display: inline-block;
	position: relative;
	cursor: pointer;
	-webkit-appearance: none;
	border: none;
	text-align: center;
	white-space: nowrap;

	&.fill {
		background: $color-button-bg;
		&:before {
			display: none;
		}
	}

	&.b {
		border: 2px solid $color-pink-transparent;
		line-height: 32px;
	}

	&.big {
		font-size: 18px;
		line-height: 54px;
		padding: 0px 40px;
		text-align: center;
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
	}

	&:hover {
		background-color: $color-pink;
		color: $color-dark;
	}

	&.active,
	&.selected {
		background-color: $color-active;
		color: $color-dark;

		&:hover,
		&:focus {
			color: $color-dark;
			background-color: $color-active;
		}
	}

	&.hightlight {
		border-color: $color-pink;
		box-shadow: 0 0 10px $color-pink;
	}

	&.confirm-delete {
		background-color: $color-delete;
		color: $color-light;

		&:hover,
		&:focus {
			background-color: $color-delete-hover;
		}
	}

	&.reject {
		background-color: $color-button-bg;
		color: $color-light;

		&:hover,
		&:focus {
			background-color: $color-button-bg-dark;
		}
	}

	@include respond-to(tablet-portrait) {
		//padding: 8px 20px;
	}
}

%btn-white {
	@extend %btn;
	background: $color-white;
	color: $color-black;
}

.button,
button,
input[type='submit'] {
	@extend %btn;

	&:disabled {
		cursor: not-allowed;
	}

	&.topButton {
		margin-top: 15px;
		float: right;

		+ .topButton {
			margin-right: 10px;
		}
	}
}

input[type='text'],
input[type='email'],
input[type='number'],
input[type='password'],
textarea,
select {
	background: $color-white;
	border: 1px solid $color-dark;
	font-family: inherit;
	padding: 8px;
	color: $color-dark;
	display: block;
	height: 36px;
	font-size: 14px;
	text-align: left;
	transition: all 0.3s ease-out;
	border-radius: 10px;
	position: relative;
	outline: none;

	&:focus {
		color: $color-black;
		border: 1px solid $color-black;
	}

	&.invalid {
		color: $color-red;
	}

	&:-webkit-autofill {
		color: $color-black;
		background: none !important;
		-webkit-box-shadow: 0 0 0px 1000px $color-white inset;
		-webkit-text-fill-color: $color-dark !important;
	}
}
