.title {
	display: block;
	clear: both;
}

.nice-link {
	display: inline-block;
	background: url('../../images/Odkaz.svg') no-repeat left center;
	background-size: 19px 20px;
	line-height: 20px;
	padding: 0px 0px 0px 30px;
	color: $color-pink;

	&:hover {
		text-decoration: underline;
	}
}

.nice-list {
	margin-bottom: 40px;
	li {
		margin-bottom: 5px;
	}

	a {
	}
}

%headline {
	display: block;
	margin-bottom: 10px;
	font-family: $font-family-headings;
	color: $color-pink;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@extend %headline;
}

h1 {
	font-size: 30px;
}

h2 {
	font-size: 20px;
}

h3,
h4,
h5,
h6 {
	font-size: 18px;
}

@include respond-to(tablet-portrait) {
	h1 {
		font-size: 50px;
	}

	h2 {
		font-size: 30px;
	}

	h3,
	h4,
	h5,
	h6 {
		font-size: 26px;
	}
}
