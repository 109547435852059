#back-link {
	padding: 8px 20px 8px 0px;
	border-radius: 1px;
	float: left;
	margin-bottom: 15px;
	font-size: 1.2em;
	line-height: 2em;
	color: #f60087;

	&:before {
		content: '';
		display: inline-block;
		margin-top: 0.6em;
		width: 0.8em;
		height: 0.8em;
		margin-right: 0.2em;
		border-top: 0.2em solid #f60087;
		border-right: 0.2em solid #f60087;
		-moz-transform: rotate(225deg);
		-webkit-transform: rotate(225deg);
		transform: rotate(225deg);
	}
}

.editMode {
	#back-link {
		color: $color-white;
		&:before {
			border-top: 0.2em solid $color-white;
			border-right: 0.2em solid $color-white;
		}
	}
}
