.tags_wrap {
	@extend %cf;
	width: 100%;
	clear: both;
	margin: 20px 0px 0px;

	@include respond-to(mobile-only) {
		width: calc(100% + 40px);
		margin: 10px 0px 0px -20px;
	}

	@include respond-to(tablet-landscape) {
		max-width: calc(100% - 450px);
		margin: 0;
	}
}

.tags_select {
	@extend %cf;
	.tags {
		.tag {
			margin: 0px 6px 6px 0px;
		}
	}

	.tag {
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			width: 0px;
			height: 0px;
			right: 5px;
			opacity: 0;
			transition: opacity 0.5s ease-in-out;
			transition-delay: 0.5s;
		}
	}

	&.edit {
		.tag {
			&.selected {
				padding-right: 36px;

				&:after {
					width: 30px;
					height: 100%;
					background-image: url('../../images/close-modal-dark.svg');
					background-repeat: no-repeat;
					background-position: center;
					opacity: 1;
				}

				&:hover {
					animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
					transform: translate3d(0, 0, 0);
					backface-visibility: hidden;
					perspective: 1000px;
					animation-iteration-count: infinite;
				}
			}
		}
	}

	&:not(.edit) {
		.tags {
			& + .tags {
				display: none !important;
			}
		}
	}

	@include respond-to(tablet-portrait) {
		display: flex;
		flex-wrap: nowrap;

		.tags {
			display: flex;
			flex-wrap: nowrap;
			flex-grow: 1;
			justify-content: flex-start;
			& + .tags {
				justify-content: flex-end;
				.tag {
					margin: 0px 0px 6px 6px;
				}
			}
		}
	}
}

.tags {
	@extend %cf;
	width: 100%;

	.tag {
		@extend %btn;
		margin: 0px 0px 6px 6px;
		text-transform: none;

		&:before {
			content: '#';
		}
		/*
		padding: 5px 10px;
		background: rgba(255, 255, 255, 0.8);
		display: inline-block;
		color: $color-dark;
		border: none;
		border-radius: 10px;
		font-size: 12px;
		line-height: 20px;
		cursor: pointer;
		outline: 0 !important;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;

		&:hover,
		&.selected {
			//background-color: var(--tag-color);
			color: $color-dark;
        }
        */
	}

	&.transparent {
		.tag:not(.selected) {
			background: transparent;
			pointer-events: none;
			color: $color-dark;
		}
	}

	@include respond-to(mobile-tablet-only) {
		&.transparent {
			padding-top: 10px;
			.tag {
				margin: 0px 6px 6px 0px;
			}
		}

		&.in-search {
			display: flex;
			align-items: flex-start;
			flex-wrap: nowrap;
			overflow-x: scroll;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */

			padding: 0px 20px;

			&::-webkit-scrollbar {
				/* WebKit */
				width: 0;
				height: 0;
			}

			.tag {
				margin: 0px 6px 0px 0px;

				&:last-child {
				}
			}
		}
	}

	@include respond-to(tablet-portrait) {
		&.in-search {
			padding: 0;
		}
	}

	@include respond-to(tablet-landscape) {
		&.in-search {
			margin: 0;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			flex-wrap: wrap;

			.tag {
				flex-grow: 1;
			}

			.tag {
				//line-height: 26px;
				//height: 36px;
			}

			&.transparent {
				.tag {
					flex-grow: initial;
					justify-content: flex-end;
				}
			}
		}
	}
}
