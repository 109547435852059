.tab-content {
	clear: both;
	position: relative;
}

.tab-pane {
	h2 {
		padding-left: 15px;
		text-shadow: 1px 1px #333;
		font-size: 24px;
		margin: 1em 0px 0.5em 0px;
	}
}
/*
.homepage {
	h1 {
		line-height: 36px;
		margin: 0;
	}
}
*/

.row_actions {
	width: 56px;
	margin-bottom: 6px;

	span {
		@extend %btn;
		@extend %bg-fade;
		border-radius: 5px;
		display: block;
		width: 56px;
		height: 100%;

		&.add {
			&:before {
				background-image: url('../../images/Plus-v-kolecku.svg');
				background-size: 18px 18px;
			}
			&:after {
				background-image: url('../../images/Plus-v-kolecku-tmave.svg');
				background-size: 18px 18px;
			}
		}

		&.delete {
			background: $color-active-transparent;
			&:before {
				background-image: url('../../images/Kos.svg');
				background-size: 18px 20px;
			}
			&:after {
				background-image: url('../../images/Kos.svg');
				background-size: 18px 20px;
			}

			&:hover {
				background: $color-active;
			}
		}
	}

	@include respond-to(tablet-portrait) {
	}
}

.expand {
	@extend %bg-fade;
	display: block;
	width: 23px;
	height: 23px;

	&:before {
		background-image: url('../../images/rozbalit.svg');
	}
	&:after {
		background-image: url('../../images/zabalit.svg');
	}

	&:hover {
		&:before {
			opacity: 1;
		}
		&:after {
			opacity: 0;
		}
	}
}

ul.itemlist {
	list-style: outside none none;
	padding: 0;

	a {
		display: block;
		border: 1px solid #eee;
		background-color: $color-white-transparent;
		margin-bottom: 5px;
		padding: 10px 10px;
		border-radius: 10px;
		text-decoration: none;
		color: $color-dark;
	}

	li {
		display: block;

		&.expandable {
			.song-row {
				& + .song-row {
					display: none;
				}
			}

			&.expanded {
				.expand {
					&:before {
						opacity: 0;
					}
					&:after {
						opacity: 1;
					}
				}

				.song-row {
					a {
						margin-bottom: 0;
						border-bottom: none;
					}

					& + .song-row {
						display: flex;

						a {
							border-top: none;
							border-radius: 0px;
						}
					}

					&:first-child {
						a {
							border-bottom-left-radius: 0px;
							border-bottom-right-radius: 0px;
						}
					}

					&:last-child {
						a {
							border-bottom-left-radius: 5px;
							border-bottom-right-radius: 5px;
							margin-bottom: 5px;
							border-bottom: 1px solid #eee;
						}
					}
				}
			}
		}

		&:hover {
			a {
				background-color: $color-white;
			}
		}
	}

	@include respond-to(mobile-tablet-portrait-only) {
		font-size: 14px;

		li {
			margin-bottom: 20px;
		}

		a {
			padding-bottom: 51px;
			position: relative;

			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 45px;
				width: calc(100% - 20px);
				left: 10px;
				height: 1px;
				background: $color-dark;
			}
			> span {
				display: block;
				line-height: 20px;

				&.item-title {
					font-size: 18px;
					margin-bottom: 10px;
					font-weight: bold;
				}

				&.expander {
					position: absolute;
					bottom: 10px;
					right: 10px;
				}

				&.item-user {
					width: 100px;
					overflow: hidden;
					text-overflow: ellipsis;
					position: absolute;
					bottom: 10px;
					left: 10px;
				}

				&.item-likes {
					width: 100px;
					position: absolute;
					bottom: 10px;
					left: 120px;
				}
			}
		}
	}

	@include respond-to(tablet-landscape) {
		display: table;

		li {
			display: block;
		}

		.song-row {
			display: flex;
			justify-content: space-between;
		}

		a {
			//display: flex;
			display: table;
			table-layout: fixed;
			//justify-content: flex-start;
			//align-items: center;

			width: 100%;
			transition: background-color 0.2s ease-in;

			> span {
				display: table-cell;
				vertical-align: middle;

				&.item-title {
					width: auto;
					font-weight: bold;
				}
				&.item-title {
				}
				&.item-author {
					width: 160px;
				}
				&.item-user {
					width: 140px;
				}
				&.item-likes {
					width: 100px;
				}
				&.expander {
					width: 40px;
				}
				&.tags-wrap {
					width: 300px;
				}
			}

			.tags {
				margin-top: 0;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: flex-end;
				width: 100%;
				.tag {
					width: auto;
					margin: 3px;
				}
			}
		}

		&.logged-in {
			a {
				width: calc(100% - 60px);
			}
		}
	}

	@include respond-to(desktop-xl) {
		a {
			> span {
				&.tags-wrap {
					width: 450px;
				}
				&.item-user {
					width: 220px;
				}
				&.item-author {
					width: 220px;
				}
			}
		}
	}
}

.list-count {
	position: absolute;
	top: 15px;
	right: 0;
	font-weight: 700;
}
