.homepage {
}

.intro {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	height: 70vh;
	width: 100%;

	h1 {
		@extend %logo;
		margin-bottom: 20px;
	}

	h2 {
	}

	.crossroads {
		padding-top: 40px;
	}

	@include respond-to(desktop) {
		.crossroads {
			padding-top: 100px;
		}
	}
}

.crossroads {
	display: flex;
	flex-direction: column;

	.button {
		width: 100%;

		& + .button {
			margin-top: 10px;
		}
	}

	@include respond-to(tablet-portrait) {
		flex-direction: row;
		align-items: center;
		width: 100%;
		max-width: 640px;

		.button {
			flex-basis: 100%;
			margin: 0px 12px;
			width: 50%;
			& + .button {
				margin-top: 0px;
			}
		}
	}
}

.heads-up {
	display: flex;
	text-align: center;
	justify-content: flex-start;
	flex-direction: column;

	article {
		width: 100%;
		margin-bottom: 20px;
	}

	h3 {
		font-size: 20px;
		margin-bottom: 16px;
		position: relative;

		&:after {
			content: '';
			display: block;
			margin: auto;
			width: 140px;
			height: 2px;
			background: $color-pink;
			bottom: 0;
			margin-top: 10px;
		}
	}

	@include respond-to(tablet-portrait) {
		//padding: 8px 20px;
		flex-direction: row;
		justify-content: space-between;

		article {
			width: 30%;
			margin-bottom: 0;
		}
	}

	@include respond-to(desktop) {
		h3 {
			font-size: 30px;
			margin-bottom: 22px;
		}
	}

	@include respond-to(desktop-xl) {
		h3 {
			font-size: 36px;
		}
	}
}

.how-it-works {
	padding-top: 120px;
	text-align: center;
	display: flex;
	align-items: center;
	width: 100%;
	flex-direction: column;
}

.help {
	margin-top: 40px;
	width: 100%;
	overflow: hidden;
	display: flex;
	align-items: flex-start;
	flex-wrap: nowrap;

	li {
		display: block;
		width: 100%;
		transition: all 0.3s ease-in-out;
		transform: translateX(0%);
		flex: 0 0 100%;
		min-width: 0;

		svg {
			max-width: 100%;
			height: auto;
			object-fit: contain;
		}
	}

	&.slide-2 {
		li {
			transform: translateX(-100%);
		}
	}
}
