#search_panel {
	@extend %cf;
	width: 100%;
	padding: 20px 0px 10px;

	@include respond-to(tablet-landscape) {
		padding: 20px 0px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

#search_wrap {
	display: flex;
	width: 100%;

	> input {
		padding-right: 50px;

		&:hover {
			& + button {
				opacity: 1;
			}
		}
	}

	> label {
		line-height: 36px;
		display: inline-block;
		margin-right: 10px;
	}

	> button {
		float: none;
		width: 36px;
		height: 36px;
		border: none;
		background: url('../../images/icon-search-black.svg') no-repeat center center;
		background-size: 18px 18px;
		margin-left: -40px;
		opacity: 0.5;

		-webkit-backdrop-filter: none;
		backdrop-filter: none;

		&:hover {
			border: none;
			background: url('../../images/icon-search-black.svg') no-repeat center center;
			background-size: 18px 18px;
			opacity: 1;
		}
	}

	@include respond-to(mobile-only) {
		> label {
			display: none;
		}
		> input {
			width: 100%;
		}
	}

	@include respond-to(tablet-portrait) {
		margin-bottom: 6px;
		width: 450px;
		> input {
			width: 300px;
		}
	}
}

#new-link {
	@extend %btn;
	float: left;

	@include respond-to(mobile-only) {
		width: 100%;
		margin-top: 15px;
	}

	@include respond-to(tablet-portrait) {
		margin-left: 30px;
	}
}
