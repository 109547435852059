%cf {
	// micro clearfix hack
	zoom: 1;
	&:before,
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

%bg-fade {
	position: relative;

	&:before,
	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		transition: opacity 0.2s linear;
		background-color: transparent;
		z-index: 1;
	}

	&:before {
		opacity: 1;
		transition: opacity 0.1s linear;
	}

	&:hover,
	&.active,
	&.selected {
		&:before {
			opacity: 0;
		}
		&:after {
			opacity: 1;
		}
	}
}

%close {
	width: 20px;
	height: 20px;
	display: block;
	background: url('../../images/close-modal-dark.svg') no-repeat center center transparent;
	transform: rotate(0deg);
	background-size: contain;
	border-radius: 50%;

	-webkit-backdrop-filter: none;
	backdrop-filter: none;
	padding: 0;

	&:hover {
		@extend %shadow;
		-webkit-backdrop-filter: none;
		backdrop-filter: none;
		//transform: rotate(90deg);
		//border: 1px solid $cRed;
	}
}

@mixin respond-to($media) {
	@if $media == mobile-portrait {
		@media only screen and (min-width: $minw-mp) {
			@content;
		}
	} @else if $media == mobile-landscape {
		@media only screen and (min-width: $minw-ml) {
			@content;
		}
	} @else if $media == mobile-only {
		@media only screen and (max-width: #{$minw-tp - 1}) {
			@content;
		}
	} @else if $media == mobile-tablet-only {
		@media only screen and (max-width: #{$minw-tl - 1}) {
			@content;
		}
	} @else if $media == small-mobile-only {
		@media only screen and (max-width: #{$minw-ml - 1}) {
			@content;
		}
	} @else if $media == mobile-landscape-only {
		@media only screen and (min-width: $minw-ml) and (max-width: #{$minw-tp - 1} ) {
			@content;
		}
	} @else if $media == tablet-portrait {
		@media only screen and (min-width: $minw-tp) {
			@content;
		}
	} @else if $media == tablet-portrait-only {
		@media only screen and (min-width: $minw-tp) and (max-width: #{$minw-tl - 1} ) {
			@content;
		}
	} @else if $media == mobile-tablet-portrait-only {
		@media only screen and (max-width: #{$minw-tl - 1}) {
			@content;
		}
	} @else if $media == tablet-only {
		@media only screen and (min-width: $minw-tp)  and (max-width: #{$minw-d - 1} ) {
			@content;
		}
	} @else if $media == tablet-landscape {
		@media only screen and (min-width: $minw-tl) {
			@content;
		}
	} @else if $media == tablet-landscape-only {
		@media only screen and (min-width: $minw-tl) and (max-width: #{$minw-d - 1} ) {
			@content;
		}
	} @else if $media == desktop {
		@media only screen and (min-width: $minw-d) {
			@content;
		}
	} @else if $media == desktop-only {
		@media only screen and (min-width: $minw-d) and (max-width: #{$minw-dxl - 1} ) {
			@content;
		}
	} @else if $media == desktop-xl {
		@media only screen and (min-width: $minw-dxl) {
			@content;
		}
	} @else if $media == breakpoint {
		@media only screen and (min-width: $full-width) {
			@content;
		}
	} @else if $media == breakpoint2 {
		@media only screen and (min-width: 1250px) {
			@content;
		}
	}
}
