%padded {
	padding: 20px;

	@include respond-to(tablet-portrait) {
		padding: 40px 45px 40px 45px;
	}
}

%shadow {
	box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.51);
}

.ReactModalPortal {
	position: relative;
	z-index: 999;
}

.modal {
	@extend %padded;
	position: fixed;
	right: -100vw;
	top: -100vh;
	width: 100%;
	height: 100vh;
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
	z-index: 999;
	//background-color: rgba(207, 212, 211, 0.81);
	padding: 20px;

	.btns {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		button {
			flex-grow: 1;
			margin: 0px 10px;
		}
	}

	.content {
		@extend %cf;
		position: absolute;
		width: calc(100% - 40px);
		height: calc(100% - 40px);
		top: 20px;
		left: 20px;
		overflow: auto;
		text-align: center;
		background-color: rgba(216, 216, 216, 0.67);
		border: 1px solid #131820;
		max-width: 600px;
		opacity: 0;
		transition: all 0.5s ease-in-out;
		border-radius: 10px;
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
	}

	.close {
		@extend %close;
		margin: 10px auto 20px auto;
	}

	.inner-content {
		@extend %cf;
		overflow: auto;
		padding: 30px;
		max-width: 760px;
		margin: auto;
	}

	h3,
	p {
		color: $color-dark;
		font-family: $font-family-bold;
		display: block;
		margin-bottom: 20px;
	}

	h3 {
		font-size: 18px;
		text-transform: uppercase;
	}

	p {
		font-family: $font-family;
		color: #58595b;
		line-height: 1.2;
	}

	&.visible {
		top: 0vw;
		right: 0vw;
		opacity: 1;
		.content {
			//top: 50%;
			opacity: 1;
		}
	}

	@include respond-to(tablet-portrait) {
		h3,
		p {
			margin-bottom: 20px;
		}

		p {
			margin-bottom: 30px;
		}

		&.small {
			.content {
				width: 460px;
			}
		}
		.inner-content {
			padding: 50px;
			max-width: 1000px;
		}
	}

	@include respond-to(desktop) {
		.close {
			position: absolute;
			top: 15px;
			right: 15px;
			margin: 0;
		}
		.content {
			transform: translate(-50%, -50%);
			top: 50%;
			left: 50%;
			width: auto;
			height: auto;
		}
		&.visible {
			.content {
				top: 40%;
			}
		}
	}
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	//filter: blur(4px);
	background: rgba(0, 0, 0, 0.5);
}
