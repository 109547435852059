@media print {
	.user-row,
	.instrument-votes,
	.edit-toolbar,
	footer,
	.transposition {
		display: none !important;
	}

	body {
		padding: 20px;
	}

	#songbody {
		.chordsRow {
			.chord {
				color: $color-dark !important;
				&:before {
					display: none !important;
				}
			}
		}
	}

	#header h1,
	#header h2,
	#header input {
		padding: 0 !important;
		border: none !important;
		color: $color-dark !important;
	}

	.tags_select {
		margin-top: -5px;
		.tags {
			.tag {
				background: transparent !important;
				padding: 0px !important;
			}

			& + .tags {
				display: none !important;
			}
		}
	}

	.centered {
		max-width: none !important;
	}

	.source {
		display: block !important;
		position: absolute;
		top: -20px;
		right: 0;
		font-size: 12px;
	}

	#header {
		padding-bottom: 0px !important;
	}

	#songbody {
		padding: 15px 0px 0px !important;
	}
}
