footer {
	display: flex;
	flex-direction: column;
	padding-top: 80px;
	width: 100%;

	.logo {
		max-width: 145px;
	}

	& > * {
		line-height: 18px;
	}

	@include respond-to(tablet-portrait) {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
}

.love {
	display: block;
	margin: 20px 0px;

	span {
		display: inline-block;
		font-size: 0px;
		line-height: 0;
		color: transparent;
		background: url('../../images/Srdicko.svg') no-repeat center center;
		background-size: contain;
		width: 18px;
		height: 18px;
		vertical-align: top;
	}

	@include respond-to(tablet-portrait) {
		text-align: center;
		margin: 0;
	}
}

.version {
	display: block;
	font-size: 13px;

	@include respond-to(tablet-portrait) {
		text-align: right;
	}
}
